<template>
  <div class="row">
    <div class="col-12" v-if="subscribes.length"><p>الخدمات المفعلة</p></div>
    <div class="col-12" v-if="!subscribes.length"><h5 class="alert alert-warning text-center g">
      قم باختيار الخدمة المراد تجربتها او استخدامها من الاسفل <i class="fa fa-arrow-down"></i>
      </h5></div>
    <template v-for="s in subscribes">
      <div class="col-12 col-lg-4 service" v-if="checkPer(s.service)" :key="s._id" @click="gotoservice(s.service)"> 
        <div class="card card-body" style="position: relative;">
          <div class="badge bg-danger noti" v-if="notis[s.service]">{{ notis[s.service] }}</div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="me-15 h-50 l-h-60 rounded text-center">
                <img :src="require('@/assets/images/gif/'+s.service+'.gif')" style="height: 90px; width: 90px !important" alt="">
              </div>
              <div class="d-flex flex-column fw-500 g">
                <h4>
                  {{ services_o[s.service].title }}
                </h4>
                <h5>
                  <div v-if="subscribeinfo(s.service).demo && subscribeinfo(s.service).valid">
                    <span class="text-primary">اشتراك تجريبي ينتهي في: <span dir="ltr">{{ subscribeinfo(s.service).exdate }}</span></span>
                  </div>
                  <div v-if="!subscribeinfo(s.service).demo && !subscribeinfo(s.service).valid">
                    <span class="text-danger">انتهى الاشتراك٫ برجاء تجديد الاشتراك</span>
                  </div>
                  <div v-if="subscribeinfo(s.service).demo && !subscribeinfo(s.service).valid">
                    <span class="text-danger">انتهت التجربة٫ قم بالاشتراك الآن</span>
                  </div>
                  <div v-if="!subscribeinfo(s.service).demo && subscribeinfo(s.service).valid">
                    <span style="color: green !important">اشتراك فعال حتى <span dir="ltr">{{ subscribeinfo(s.service).exdate }}</span></span>
                  </div>
                </h5>
                  <span class="text-muted"><strong class="text-success">اضغط هنا للذهاب للنظام</strong>
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="col-12" v-if="checkPer('subscribe')"><p>الخدمات الموجودة</p></div>
    <div class="col-12 text-center g" v-if="loading">
      <img :src="require('@/assets/images/loading.svg')" alt="">
    </div>
    <template v-if="checkPer('subscribe')">
      <div class="col-12 col-lg-4 g service" v-for="service in services" :key="service.code" v-b-modal.modal-1 @click="current = service">
        <div class="card card-body">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="me-15 h-50 l-h-60 rounded text-center">
                  <img :src="require('@/assets/images/gif/'+service.code+'.gif')" style="height: 90px; width: 90px !important" alt="">
                </div>
                <div class="d-flex flex-column fw-500 g">
                  <h4>
                    {{ service.title }}
                  </h4>
                  <span class="text-muted">
                    {{ service.bio }} <br> <strong class="text-success">اضغط للتفعيل / للتجربة</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <b-modal id="modal-2" title="ربط بالواتس الذكي" hide-footer>
      <div class="form-group">
        <label for="">رقم الجوال (لتسجيل الدخول في الواتس الذكي)</label>
        <input type="text"
          class="form-control" v-model="whats_phone" placeholder="اكتب هنا...">
      </div>
      <div class="form-group">
        <label for="">كلمة المرور (لتسجيل الدخول في الواتس الذكي)</label>
        <input type="text"
          class="form-control" v-model="whats_password" placeholder="اكتب هنا...">
      </div>
      <div class="col-12 text-center g">
        <button class="btn btn-primary" @click="linkwhats()">
          ربط الان حساب الواتس الذكي
        </button>
      </div>
    </b-modal>  
    <b-modal id="modal-1" title="تفاصيل الخدمة" hide-footer size="lg">
        <div class="row" v-if="current.title">
          <div class="col-12 col-lg-6  c">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="me-15 h-50 l-h-60 rounded text-center">
                  <img :src="require('@/assets/images/gif/'+current.code+'.gif')" style="height: 90px; width: 90px !important" alt="">
                </div>
                <div class="d-flex flex-column fw-500 g">
                  <h4>
                    {{ current.title }}
                  </h4>
                  <h5 v-html="current.price">
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 c text-center" v-if="subscribeinfo(current.code).valid && !subscribeinfo(current.code).demo">
            <button class="btn btn-success btn-block btn-lg" @click="gotoservice(current.code)">
              <i class="fa fa-external-link"></i>
              الذهاب لنظام "{{ current.title }}"
            </button>
            ينتهي الاشتراك في: <span dir="ltr">{{ subscribeinfo(current.code).exdate }}</span> <a href="javascript:;" class="btn btn-sm text-primary fa fa-refresh" @click="buy(current.code)"> تجديد</a>
          </div>
          <div class="col-12 col-lg-6 c text-center" v-if="!subscribeinfo(current.code).valid || subscribeinfo(current.code).demo">
            <img :src="require('@/assets/images/loading.svg')" width="50" alt="" v-if="loading">
            <div v-if="!loading && current.code == 'bus'">
              <button class="btn btn-success btn-block" @click="openbus()">
                <i class="fa fa-external-link"></i>
                الذهاب لنظام التوصيل المدرسي
              </button>
            </div>
            <div v-if="!loading && current.code != 'bus'">
              <button class="btn btn-success btn-block btn-lg" @click="buy(current.code)">
                اشتراك الآن في الخدمة
              </button>
              <button class="btn btn-primary btn-block" v-if="!subscribeinfo(current.code).demo && !subscribeinfo(current.code).valid && current.have_demo == 'yes'" @click="demo(current.code)">
                <i class="fa fa-play"></i>
                الحصول على فترة تجريبية
              </button>
              <button class="btn btn-secondary btn-block" v-if="current.code == 'whatsapp'" v-b-modal.modal-2>
                <i class="fa fa-whatsapp"></i>
                ربط بحساب الواتس الذكي
              </button>
              <span class="btn btn-default btn-block" v-if="subscribeinfo(current.code).demo">
                تنتهي التجربة في: <span dir="ltr">{{ subscribeinfo(current.code).exdate }}</span>
              </span>
            </div>
          </div>
          <div class="col-12"><hr></div>
          <div class="col-12" v-html="current.content"></div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem('user')),
      services: [],
      services_o: {},
      current: {},
      subscribe: {},
      subscribes: [],
      notis: {},
      whats_phone: "",
      whats_password: ""
    }
  },
  created(){
    var g = this;
    this.loading = true
    $.post(api + '/general/services', {
        jwt: this.user.jwt
    }).then(function(r){
        if(r.status != 100){
            alert(r.response)
        }else{
            g.services = r.response
            g.services.forEach(function(a){
              g.services_o[a.code] = a
            })
        }
    }).fail(function(){
        g.loading = false
    })
    $.post(api + '/user/general/services-notis', {
        jwt: this.user.jwt
    }).then(function(r){
        if(r.status != 100){
            alert(r.response)
        }else{
            g.notis = r.response
        }
    }).fail(function(){
        g.loading = false
    })
    $.post(api + '/user/subscribe/subscribes', {
        jwt: this.user.jwt
    }).then(function(r){
        g.loading = false
        if(r.status != 100){
            alert(r.response)
        }else{
            g.subscribes = r.response
            if(g.subscribes.length == 0){
              $("#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:contains('الخدمات الفعالة')").remove();
              $("#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:contains('الصفحات')").remove();
            }
            if($(".sdfsdfsdfsds").length == 0){
            g.subscribes.forEach(function(a){
              var icon = "star";
              if(a.service == "students"){
                icon = "users"
              }
              if(a.service == "teachers"){
                icon = "user"
              }
              if(a.service == "whatsapp"){
                icon = "whatsapp"
              }
              if(a.service == "results"){
                icon = "paper-plane"
              }
              if(a.service == "calls"){
                icon = "mobile"
              }
              if(a.service == "lessons"){
                icon = "check"
              }
              $(`<li class="nav-item sdfsdfsdfsds" style='background: #E7FBE6'><a href="/_${a.service}" class="d-flex align-items-center">
                <i class='fa fa-${icon}'></i>
                <span class="menu-title text-truncate">${a.title}</span></a></li>`).insertAfter(`#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:nth-child(3)`)
              })
            }
        }
    }).fail(function(){
        g.loading = false
    })
  },
  methods: {
    openbus(){
      var g = this;
      window.open(`https://tahdirbus.tahdir.net/login?auto-register&name=${encodeURI(this.user.name)}&phone=${encodeURI(this.user.phone)}&code=tahdir&password=${encodeURI(this.user._id)}`)
    },
    linkwhats(){
      var g = this;
      $.post(api + '/user/whatsapp/link-whats', {
          jwt: this.user.jwt,
          phone: g.whats_phone,
          password: g.whats_password
      }).then(function(r){
          g.loading = false
          if(r.status != 100){
              alert(r.response, 200)
          }else{
              alert("تم الربط بنجاح, قم بـذهاب لخدمة رسائل الواتساب التلقائية الان للتاكد من ربط الجهاز.", 100);
              setTimeout(() => {
                window.location = '/_whatsapp'
              }, 2000);
          }
      }).fail(function(){
        alert("حدث خطأ")
          g.loading = false
      })
    },
    subscribeinfo(code){
      var g = this, r = {};
      g.subscribes.forEach(function(a){
        if(a.service == code){
          r = {
            valid: new Date(a?.exdate) > new Date() ? true : false,
            demo: a.demo,
            exdate: a.exdate
          }
        }
      })
      return r;
    },
    gotoservice(id){
      if(this.subscribeinfo(id).valid){
        window.location = '/_' + id
      }else{
         this.$router.push('/expired?service=' + id)
      }
    },
    buy(){
      this.$router.push('/subscribe?code=' + this.current.code)
    },
    demo(code){
      var g = this;
      this.loading = true;
      $.post(api + '/user/subscribe/demo', {
          jwt: this.user.jwt,
          code: this.current.code
      }).then(function(r){
          g.loading = false
          if(r.status != 100){
              alert(r.response)
          }else{
            location.reload()
          }
      }).fail(function(){
          g.loading = false
          alert("حدث خطأ")
      })
    }
  }
}
</script>

<style>
.service{
  transition: transform .2s;
}
.service .card:hover{
  transform: scale(1.05);
  border: 1px solid blue;
  cursor: pointer;
}
.tip{
  text-align: left;
  border: none !important
}
.noti{
  position: absolute;
  top: 0px; 
  right: 0px;
  width: auto;
  font-size: 20px;
  border-radius: 0px 5px 0px 8px;
  filter: drop-shadow(0px 5px 5px #999);
}
#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:nth-child(3){
  margin-top: 20px !important;
}
</style>